<template>
  <!-- 社团动态 -->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" v-if="manage||chief" :model="queryParams">
        <el-form-item class="area_img">
          <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item>
          <el-input size="small" v-model="queryParams.content" clearable placeholder="请输入动态关键字" @change="resetPageAndRefresh"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
      </el-form>

      <!-- 列表 -->
      <div v-if="storeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="storeList" :isEditor="isEditor" :total="storeTotal" :span='12' @refresh="refresh">
        <template slot-scope="scope">
          <div class="list" @click="requireDetails(scope.row)">
            <div class="con-head">
              <div class="area">
                <p class="con-txt txtwidth" :title="scope.row.content">{{ scope.row.content?scope.row.content:'-' }}</p>
                <p class="mission">动态内容</p>
              </div>
              <div class="area fr" v-if="scope.row.extend">
                <p class="con-txt" :title="scope.row.extend.userName">{{ scope.row.extend.userName?scope.row.extend.userName:'-' }}</p>
                <p class="mission fr">发布人</p>
              </div>
              <div class="area">
                <p class="con-txt txtwidth" :title="scope.row.name">{{ scope.row.name?scope.row.name:'-' }}</p>
                <p class="mission">所属社团</p>
              </div>
              <div class="area" v-if="scope.row.extend">
                <p class="con-txt txtwidth" :title="scope.row.name">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                <p class="mission">所属学校</p>
              </div>
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">发布时间</span>
                  <span class="condel">{{ scope.row.addTime }}</span>
                </div>
                <div class="time">
                  <span class="contit">点赞人数</span>
                  <span class="condel">{{ scope.row.praiseNumber?scope.row.praiseNumber:'0'  }}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time" v-if="scope.row.extend">
                  <span class="contit">学校名称</span>
                  <span class="condel">{{ scope.row.extend.schoolName }}</span>
                </div>
                <div class="time">
                  <span class="contit">评论人数</span>
                  <span class="condel">{{ scope.row.commentNumber?scope.row.commentNumber:'0' }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle></el-button>
          <el-button type="danger" icon="el-icon-delete" circle></el-button>
        </template>
      </paged-list>
    </div>
    
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="36.5%"
      >
      <div class="details">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">{{ organizeDetails.addTime }}</p>
              <p class="mission">发布时间</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.name }}</p>
              <p class="mission">所属社团</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.praiseNumber?organizeDetails.praiseNumber:'0' }}</p>
              <p class="mission">点赞人数</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.commentNumber?organizeDetails.commentNumber:'0' }}</p>
              <p class="mission">评论人数</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">发布人</p>
            <el-image :src="organizeDetails.extend.headImg+'?x-oss-process=image/resize,m_fill,h_150,w_150'" class="pig" alt=""></el-image>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.extend.userName }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="organizeDetails.extend.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else>
              </p>
              <p class="mission">{{ organizeDetails.extend.schoolName }}{{ organizeDetails.fade? " | "+organizeDetails.fade : "" }}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.extend.mobile }}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.extend.schoolName }}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="taskcon">
            <p class="contit">动态内容</p>
            <div class="conimg w100p">
              <div>{{ organizeDetails.content }}</div>
              <div class="connum w100p">
                <el-image :src="img+ '?x-oss-process=image/resize,m_fill,h_150,w_150'" alt="" v-for="img in organizeDetails.imgList" :key="img.id"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import { mapState, mapActions } from "vuex";
export default {
  name:'organizeTrends',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      /** 点编辑出现遮罩层 */
      editSucce: true,
      /** 角色权限 */
      member: false, ceo: false, manage: false, chief: true,
      drawer: false,
      details:{
      },
      queryParams: {
        content: "",
        province: null,
        city: null,
        area: null,
        schoolId: null,
      },
      options: [],
      organizeDetails: {
        extend: {},
        imgList: {}
      },
      pageApi:'getNewsList',
      insertApi: 'newsAdd',
      editorApi: 'newsEdit',
      deleteApi: 'newsDelete',
    };
  },
  computed: {
    ...mapState("organizeTrends", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("organizeTrends", ["getNewsList", "newsAdd", "newsEdit", "newsLoad", "newsDelete"]),
    ...mapActions("commonOptions", ["getSchoolTree"]),
    /** 社团动态 */
    async requireDetails(row) {
      try {
        let res = await this.newsLoad({id: row.id})
        this.organizeDetails = res
        console.log(this.organizeDetails.extend)
      } catch (error){
      }finally{
        this.drawer = true
      }
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.refresh ()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 140px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}
.w250 { width: 250px !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }


/deep/#el-drawer__title{ background:$c-title;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;padding: 20px; }
.details{ width: 100%; height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; line-height: 14px; font-size: 14px;
    .headtit { color: $c-2; line-height: 18px; font-size: 18px; line-height: 18px; background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt { line-height: 20px; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
        .mission{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-1; padding: 20px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 22px; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-bodyFirst{ 
    .taskcon { padding: 10px 0;
      .contit{ color: $c-1; padding: 10px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .conimg { 
        .connum{ display: inline-block;  margin-top: 20px;
          .el-image { display: inline-block; width: 29%; margin-right: 20px; border-radius: 4px; }
          &:nth-child(3n-1) { margin-right: 0; }
        }
        .w100p { width: 100% !important; }
      }
    }
  }
}
</style>